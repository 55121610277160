import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col, Card, Button, Image, Stack } from "react-bootstrap";
import { Grid, Pagination } from "swiper/modules";

import btnGooglePlay from "../assets/img/btn_store_google.png";
import btnAppStore from "../assets/img/btn_store_apple.png";

import game_angrybirdmatch from "../assets/img/game_angrybirdmatch.png";
import wwe from "../assets/img/game_wwechampions.png";
import game_wheeloffortune from "../assets/img/game_wheeloffortune.png";
import game_arcanerush from "../assets/img/game_arcanerush.png";
import game_LegendsofRuneterra from "../assets/img/game_LegendsofRuneterra.png";
import game_mm from "../assets/img/game_m&m.png";
import game_marvelstrikeforce from "../assets/img/game_marvelstrikeforce.png";
import game_myvegasbingo from "../assets/img/game_myvegasbingo.png";
import game_startreckcommand from "../assets/img/game_startreckcommand.png";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const data = [
  {
    id: 1,
    title: "WWE Champions",
    image: wwe,
    googleLink: "https://play.google.com/store/apps/details?id=com.scopely.whiplash&hl=en",
    appleLink: "https://apps.apple.com/nz/app/wwe-champions-2019/id1017432937",
  },
  {
    id: 2,
    title: "Legends of Runeterra",
    image: game_LegendsofRuneterra,
    googleLink: "https://play.google.com/store/apps/details?id=com.riotgames.legendsofruneterra&hl=en&gl=US",
    appleLink: "https://apps.apple.com/us/app/legends-of-runeterra/id1480617557",
  },
  {
    id: 3,
    title: "Angry Birds Match",
    image: game_angrybirdmatch,
    googleLink: "https://play.google.com/store/apps/details?id=com.rovio.abmatch3&hl=en&gl=US",
    appleLink: "https://apps.apple.com/us/app/angry-birds-match-3/id1144401880",
  },
  {
    id: 9,
    title: "Marvel Strike Force",
    image: game_marvelstrikeforce,
    googleLink: "https://play.google.com/store/apps/details?id=com.foxnextgames.m3&hl=en&gl=US",
    appleLink: "https://apps.apple.com/nz/app/marvel-strike-force-squad-rpg/id1292952049",
  },
  {
    id: 4,
    title: "Arcane Rush",
    image: game_arcanerush,
    googleLink: "https://play.google.com/store/apps/details?id=com.alleylabs.battlegrounds&hl=en&gl=US",
    appleLink: "https://apps.apple.com/vn/app/arcane-rush-battlegrounds/id6448436365",
  },
  {
    id: 5,
    title: "Star Trek Fleet Command",
    image: game_startreckcommand,
    googleLink: "https://play.google.com/store/apps/details?id=com.scopely.startrek&hl=en_US&pli=1",
    appleLink: "https://apps.apple.com/us/app/star-trek-fleet-command/id1427744264",
  },
  {
    id: 6,
    title: "MyVegas Bingo",
    image: game_myvegasbingo,
    googleLink: "https://play.google.com/store/apps/details?id=com.playstudios.myvegas.bingo&hl=vi&gl=US",
    appleLink: "https://apps.apple.com/us/app/myvegas-bingo-bingo-games/id1514182046",
  },
  {
    id: 7,
    title: "M&M's Adventure",
    image: game_mm,
    googleLink: "https://play.google.com/store/apps/details?id=com.tiltingpoint.mnms",
    appleLink: "https://apps.apple.com/us/app/m-ms-adventure-puzzle-games/id1525923587",
  },
  {
    id: 8,
    title: "Wheel Of Fortune - Words",
    image: game_wheeloffortune,
    googleLink: "https://play.google.com/store/apps/details?id=com.sonypicturestelevision.wordsoffortune&hl=vi&gl=US",
    appleLink: "https://apps.apple.com/us/app/wheel-of-fortune-words/id1570303461",
  },
];

const Games = () => {
  return (
    <React.Fragment>
      <section className="section" id="games">
        <Container>
          <Row className=" justify-content-center">
            <Col lg={8}>
              <div className="text-center title mb-5">
                <h1 className="mb-3">GAMES</h1>
              </div>
            </Col>
          </Row>
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 1,
                grid: {
                  rows: 1,
                  fill: "row",
                },
              },
              768: {
                slidesPerView: 2,
                grid: {
                  rows: 2,
                  fill: "row",
                },
              },
              992: {
                slidesPerView: 2,
                grid: {
                  rows: 3,
                  fill: "row",
                },
              },
              1200: {
                slidesPerView: 3,
                grid: {
                  rows: 3,
                  fill: "row",
                },
              },
            }}
            spaceBetween={70}
            pagination={{
              clickable: true,
            }}
            modules={[Grid, Pagination]}
            className="gameSwiper"
          >
            {data.map((item: any, key: any) => (
              <SwiperSlide key={key}>
                <Card className="game-box text-center">
                  <Card.Body>
                    <div className="game-title">
                      <h4 className="game-name">{item.title}</h4>
                    </div>

                    <div className="game-image mt-1">
                      <Image src={item.image} className="img-fluid" alt="" />
                    </div>
                    <div className="mt-2">
                      <Stack direction="horizontal" gap={2}>
                        {item.googleLink && (
                          <Button
                            variant="link"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.open(item.googleLink, "_blank");
                            }}
                          >
                            <Image src={btnGooglePlay} alt="" style={{ width: item.appleLink ? "100%" : "50%" }} />
                          </Button>
                        )}
                        {item.appleLink && (
                          <Button
                            variant="link"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.open(item.appleLink, "_blank");
                            }}
                          >
                            <Image src={btnAppStore} alt="" style={{ width: item.googleLink ? "100%" : "50%" }} />
                          </Button>
                        )}
                      </Stack>
                    </div>
                  </Card.Body>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Games;
